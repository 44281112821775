import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Summary from '../components/Summary';
import { saveWasteEntry } from '~/WasteEntry/actions';

const mapStateToProps = ({ wasteEntry, settings: { app: appSettings } }) => ({
  wasteEntry,
  appSettings,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  saveWasteEntry,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Summary);
