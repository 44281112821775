import { combineReducers } from "redux";
import wasteEntryReducers from "./WasteEntry/reducers";
import appMessage from "./AppMessage/reducers";
import appReducers from "./App/reducers";
import activationReducers from "./Activation/reducers";
import userConfig from "./UserConfig/reducers";
import home from "./Home/reducers";
import settingsReducers from "./Settings/reducers";
import { forEach, entries, has } from "lodash";
import logger from "debug";

const allReducers = {};
const addReducers = (reducers) => {
  forEach(entries(reducers), ([key, value]) => {
    if (has(allReducers, key)) {
      logger("wastenot:error")("Duplicate reducers: ", key);
    }
    allReducers[key] = value;
  });
};

addReducers(settingsReducers);
addReducers(wasteEntryReducers);
addReducers(appReducers);
addReducers(activationReducers);
addReducers({ appMessage });
addReducers({ userConfig });
addReducers({ home });

const rootReducer = combineReducers(allReducers);

export default rootReducer;
