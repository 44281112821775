import { activate } from "../actions";

export const initialState = {
  status: "init",
  tablet_id: 0,
  access_token: null,
  unit: {
    id: 0,
    name: "",
    system: "",
    language: {},
  },
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case activate.RESET:
      return initialState;

    case activate.REQUEST: {
      return {
        ...state,
        error: null,
        status: "request",
      };
    }

    case activate.SUCCESS: {
      const { access_token, unit, tablet_id } = action.payload;
      return {
        ...state,
        access_token,
        unit,
        tablet_id,
        status: "success",
      };
    }

    case activate.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
