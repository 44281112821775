import originalFetch from "isomorphic-fetch";
import { put, call, select, takeLatest } from "redux-saga/effects";
import fetchRetry from "fetch-retry";
import logger from "debug";
import { getI18n } from "react-i18next";
import { toUpper, find, get, omit, join, map, round } from "lodash";
import { saveWasteEntry, newWasteEntry, updateProfile } from "../actions";
import { showAppMessage } from "~/AppMessage/actions";
import { setLastSync } from "~/App/actions";
import getWasteEntry from "../selectors";
import getAppSettings from "~/Settings/selectors";
import getAccessToken from "~/Activation/selectors";
import WeightCalculator from "~/Summary/WeightCalculator";
import DESTINATIONS from "~/Destination/destinations";
import { fetchProfiles } from "~/Settings/actions";
import { precacheContainerImages } from "~/Settings/precache";
import { baseApiUrl } from "~/utils/apiHelpers";
import { DateTime } from "luxon";
import { activate } from "~/Activation/actions";
import { navigate } from "@reach/router";

// Workbox only retries requests that fail due to NetworkError
// This ensures waste entries are also retried when server is down (returns e.g. 503)
const fetch = fetchRetry(originalFetch, {
  retries: 1000, // this will try for about ~8h
  retryDelay: 30000, // 30s
  retryOn(attempt, error, response) {
    // retry when online and on 5xx status codes
    return response && response.status >= 500 && navigator.onLine;
  },
});

export const transformWasteEntry = (wasteEntry, appSettings, notes) => {
  const isExact = wasteEntry.quantity.type === "exact";
  const typeOrReason =
    wasteEntry.kind === "RED_KITCHEN_WASTE"
      ? wasteEntry.whyTossed
      : wasteEntry.kind;
  const weightCalculator = new WeightCalculator(wasteEntry);
  const unitSystem = get(appSettings, "unitSystem");
  const record = {
    // ISO with TZ eg. 2021-11-14T11:29:09-07:00
    created_date: DateTime.now()
      .startOf("second")
      .toISO({ suppressMilliseconds: true }),

    profile_id: wasteEntry.profile.id,

    kind_of_waste: toUpper(typeOrReason),
    waste_destination: find(DESTINATIONS, { slug: wasteEntry.destination })
      .submit,
    type_of_waste: wasteEntry.type, // eg dry

    exact_weight_reported: isExact, // exact weight _or_ volume

    // non-exact
    container_fill_level: isExact ? 0 : wasteEntry.quantity.percentage,
    container_id: isExact ? "" : wasteEntry.container.id,
    num_containers: isExact ? 0 : wasteEntry.quantity.count,

    // This is the raw data. Store exactly what the user selected with no conversions or calculations.
    amount: isExact ? wasteEntry.quantity.amount : 0,
    unit: isExact ? wasteEntry.quantity.units : "",

    // Post calculation and conversion data here. This data uses the WeightCalculator on the WasteNotGlobalPWA.
    // This is the data the user sees on their screen.
    calculated_amount: round(weightCalculator.getWeight(unitSystem), 2),
    calculated_unit: unitSystem === "imperial" ? "lbs" : "kg", // Get from user preferences,
    notes,
  };
  return isExact
    ? omit(record, ["container_fill_level", "container_id", "num_containers"])
    : omit(record, ["amount", "unit"]);
};

const doFetch = async (wasteEntry, accessToken) => {
  const response = await fetch(`${baseApiUrl}/waste`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(wasteEntry),
  });
  if (response.status === 401) {
    throw new Error("INVALID_ACCESS_TOKEN");
  }
  const json = await response.json();
  return {
    result: json,
    status: response.status,
  };
};

function* saveWasteEntryWorker(action) {
  const notes = action.payload;
  yield put(saveWasteEntry.request());

  const appSettings = yield select(getAppSettings);
  const wasteEntry = yield select(getWasteEntry);
  const transformed = transformWasteEntry(wasteEntry, appSettings, notes);
  const t = (key) =>
    getI18n().t(key, {
      ns: ["saveWasteEntry", "activation_page"],
      lng: localStorage.getItem("lngTemp") || "en",
    });

  try {
    const accessToken = yield select(getAccessToken);
    const response = yield call(doFetch, transformed, accessToken);
    const { result, status } = response;
    if (status === 200) {
      logger("wastenot:info")("Recorded waste entry: ", transformed);
      yield put(showAppMessage({ variant: "success", message: t("success") }));
      yield put(saveWasteEntry.success({ result }));
      yield put(newWasteEntry());
      yield put(setLastSync({ lastSyncDate: new Date().getTime() }));
    } else {
      // this is a problem with the payload, no point in saving for later sync
      const { errors } = result;
      const msg = join(map(errors, "msg"), " ");
      logger("wastenot:error")("Failed to record waste entry: ", msg);
      logger("wastenot:info")("Waste entry: ", transformed);
      yield put(saveWasteEntry.failure({ errors }));
      yield put(
        showAppMessage({
          variant: "error",
          message: `${t("fail")}: ${msg}`,
        }),
      );
    }
  } catch (e) {
    // any error in saving, offline or server fault, and workbox caches for later
    yield put(newWasteEntry());
    yield put(
      showAppMessage({
        variant: "info",
        duration: 5000,
        message: t("success_offline"),
      }),
    );
    logger("wastenot:warn")("Queuing for later submission: ", transformed);

    if (e.message === "INVALID_ACCESS_TOKEN") {
      yield put(activate.reset());
      yield put(
        showAppMessage({
          variant: "error",
          duration: 3000,
          message: t("invalid"),
        }),
      );
      navigate("/activation");
    }
  }
}

// when the profiles are fetched, take any existing wasteEntry and update its embedded profile
// todo: not sure why we are grabbing profile-related info from the wasteEntry, and not from profiles!!
function* updateProfileWorker(action) {
  const wasteEntry = yield select(getWasteEntry);
  const { profiles } = action.payload;
  const profile = find(profiles, { id: get(wasteEntry, "profile.id") });
  yield put(updateProfile({ profile }));
  precacheContainerImages(profile);
}

function* saveWasteEntryWatcher() {
  yield takeLatest(saveWasteEntry.TRIGGER, saveWasteEntryWorker);
}

function* fetchProfilesWatcher() {
  yield takeLatest(fetchProfiles.SUCCESS, updateProfileWorker);
}

export default [saveWasteEntryWatcher, fetchProfilesWatcher];
