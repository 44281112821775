export default {
  typography: {
    fontFamily: "Noto Sans Regular",
    h1: {
      fontFamily: "Noto Sans Regular",
      letterSpacing: -2,
    },
    h2: {
      fontFamily: "Noto Sans Regular",
      fontSize: "2.6rem",
      textTransform: "uppercase",
      marginBottom: 20,
      paddingTop: 15,
      wordSpacing: -2,
      fontWeight: 600,
    },
    h3: {
      fontFamily: "Noto Sans Regular",
      fontSize: "2rem",
      wordSpacing: -2,
    },
    h4: {
      fontFamily: "Noto Sans Regular",
    },
    h5: {
      fontFamily: "Noto Sans Regular",
    },
    h6: {
      fontFamily: "Noto Sans Regular",
    },
    subtitle1: {
      fontFamily: "Noto Sans Regular",
      fontSize: "1.1rem",
      fontWeight: 600,
    },
    subtitle2: {
      fontFamily: "Noto Sans Regular",
      fontSize: "1rem",
      fontWeight: 600,
    },
    caption: {
      fontFamily: "Noto Sans Regular",
      fontSize: "1.2rem",
      wordSpacing: -2,
    },
  },
};
