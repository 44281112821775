import { createAction } from 'redux-actions';
import { createRoutine } from 'redux-saga-routines';

const setLastSync = createAction('SET_LAST_SYNC');
setLastSync.TYPE = setLastSync.toString();

const fetchBuildInfo = createRoutine('FETCH_BUILD_INFO');

export { setLastSync, fetchBuildInfo };

