import { setLastSync } from "../actions";

export const initialState = {
  lastSyncDate: null, // ms timestamp
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case setLastSync.TYPE: {
      const { lastSyncDate } = action.payload;
      return {
        ...state,
        lastSyncDate,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
