import fetch from "isomorphic-fetch";
import { put, call, takeLatest, select } from "redux-saga/effects";
import { isNil, isEmpty } from "lodash";
import logger from "debug";
import { getI18n } from "react-i18next";
import { fetchKitchens } from "../actions";
import { showAppMessage } from "~/AppMessage/actions";
import getAccessToken from "~/Activation/selectors";
import { baseApiUrl } from "~/utils/apiHelpers";
import { activate } from "~/Activation/actions";
import { navigate } from "@reach/router";

const doFetch = (accessToken) =>
  fetch(`${baseApiUrl}/kitchens`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      if (response.status === 401) {
        throw new Error("INVALID_ACCESS_TOKEN");
      }
      return response.json();
    })
    .then((kitchens) => kitchens);

function* fetchKitchensWorker(action) {
  const t = (key) =>
    getI18n().t(key, { ns: ["settings_page", "activation_page"] });

  yield put(fetchKitchens.request());

  const accessToken = yield select(getAccessToken);

  try {
    const kitchens = yield call(doFetch, accessToken);

    if (isEmpty(kitchens)) {
      yield put(
        showAppMessage({ variant: "warning", message: t("error.no_kitchens") }),
      );
      yield put(fetchKitchens.success({ kitchens: [] }));
    } else if (!isNil(kitchens)) {
      yield put(fetchKitchens.success({ kitchens }));
    } else {
      // NB service worker will try cache first
      yield put(fetchKitchens.failure({ error: "Error requesting kitchens" }));
    }
  } catch (e) {
    logger("wastenot:error")("Error fetching kitchens: ", e);
    yield put(fetchKitchens.failure({ error: "Error requesting kitchens" }));
    if (e.message === "INVALID_ACCESS_TOKEN") {
      yield put(activate.reset());
      yield put(
        showAppMessage({
          variant: "error",
          duration: 3000,
          message: t("invalid"),
        }),
      );
      navigate("/activation");      
    }    
  }
}

function* fetchKitchensWatcher() {
  yield takeLatest(fetchKitchens.TRIGGER, fetchKitchensWorker);
}

export default [fetchKitchensWatcher];
