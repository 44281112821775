import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import {
  InputAdornment,
  MenuItem,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Box,
  Button,
  CircularProgress,
  Select,
  Dialog,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import {
  get,
  find,
  isEmpty,
  sortBy,
  map,
  toLower,
  has,
  join,
} from "lodash";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { Layout } from "~/Layout";
import getCrumbs from "~/Layout/crumbs";
import overrides from "~/Theme/overrides";
import { precacheAllImages } from "~/Settings/precache";
import { LANGUAGES } from "~/i18n";
import ProfileDialog from "./ProfileDialog";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useWatchConnection } from "../hooks/useWatchConnection";

const useStyles = makeStyles((theme) => ({
  form: {
    height: "100%",
  },
  container: {
    minHeight: "100%",
    width: "100%",
  },
  formControl: {
    width: "80vw",
    marginBottom: theme.spacing(0.5),
  },
  row: {
    marginLeft: "10vw",
  },
  field: {
    fontSize: "2rem",
  },
  spinner: {
    position: "absolute",
  },
  formLabel: {
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.54)",
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectProfiles: {
    fontSize: "2rem",
    padding: "0 16px",
  },
  profiles: {
    flex: 1,
  },
  connectionErrorDialogBox: {
    padding: "40px 80px",
    textAlign: "center",
  },
  connectionErrorTitle: {
    fontSize: "1.5rem",
    color: "#707070",
    fontWeight: "bold",
  },
  connectionErrorMessage: {
    fontSize: "1.2rem",
    marginTop: "16px",
    color: "#848484",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  connectionErrorButton: {
    fontSize: "1.2rem",
    color: "#848484",
    marginLeft: "4px",
    textDecoration: "underline",
    "&:hover": {
      color: "#707070",
    },
  },
}));

const theme = createTheme({
  overrides: {
    ...overrides,
    // double the size of the initial select input label
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 1px) scale(1.1)",
        fontWeight: "bold",
      },
      formControl: {
        transform: "translate(0, 30px) scale(2)",
      },
    },
    MuiButton: {
      root: {
        ...overrides.MuiButton.root,
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: 30,
        letterSpacing: "normal",
      },
    },
  },
});

const Settings = ({
  resetWasteEntry,
  fetchKitchens,
  fetchProfiles,
  appSettings,
  fetchKitchensResult,
  setKitchen,
  fetchProfilesResult,
  setProfiles,
  setUnitSystem,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["settings_page", "languages"]);

  useEffect(() => {
    // unit and id are already set
    fetchKitchens();

    // if we have a kitchen selected, also refresh the profiles
    const kitchen = get(appSettings, "kitchen");
    if (has(kitchen, "id")) {
      fetchProfiles({ kitchen });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const [submitting, setSubmitting] = useState(false);

  // online/offline messaging
  const { online } = useWatchConnection();

  const validationSchema = Yup.object().shape({
    kitchenId: Yup.number().required(t("required.kitchen")),
    profiles: Yup.array()
      .min(1, t("required.profiles"))
      .required(t("required.profiles")),
  });

  const handleSubmit = async () => {
    setSubmitting(true);
    resetWasteEntry();

    await precacheAllImages(appSettings);

    navigate("/app");
    setSubmitting(false);
    setDirty(false);
  };

  // profile dialog
  const [open, setOpen] = useState(false);
  const handleOpenProfileDialog = () => {
    setOpen(true);
  };

  // initialize the forms with empty values
  // when the profiles and kitchens come in, set their values (if available)
  const { kitchens, status: status1 } = fetchKitchensResult;
  const { profiles, status: status2 } = fetchProfilesResult;
  const loaded = status1 === "success" && status2 === "success";

  const formikRef = useRef();
  useEffect(() => {
    if (formikRef.current && loaded) {
      const { resetForm } = formikRef.current;
      resetForm({
        values: {
          kitchenId: get(appSettings, "kitchen.id"),
          profiles: get(appSettings, "profiles"),
          tabletId: get(appSettings, "tabletId"),
          unitSystem: get(appSettings, "unitSystem"),
        },
      });
    }
  }, [loaded, appSettings]);

  // formik dirty doesn't work
  const [dirty, setDirty] = useState(false);

  return (
    <Layout crumbs={getCrumbs("home/settings")}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          kitchenId: "",
          profiles: [],
          tabletId: get(appSettings, "tabletId"),
          unitSystem: get(appSettings, "unitSystem"),
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => {
          return (
            <ThemeProvider theme={theme}>
              <Form noValidate className={classes.form}>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  className={classes.container}
                  spacing={1}
                >
                  <Grid item className={classes.row}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="unitName"
                        disabled
                        label={t("unitName")}
                        value={get(appSettings, "unit.name")}
                        InputProps={{
                          classes: {
                            root: classes.field,
                          },
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item className={classes.row}>
                    <FormControl className={classes.formControl}>
                      <InputLabel>{t("kitchen")}</InputLabel>
                      <Select
                        disabled={
                          isEmpty(appSettings.unit) ||
                          isEmpty(kitchens) ||
                          !online
                        }
                        name="kitchenId"
                        value={values.kitchenId}
                        className={classes.field}
                        error={errors.kitchenId && touched.kitchenId}
                        onChange={(event) => {
                          handleChange(event);
                          const kitchenId = event.target.value;
                          const kitchen = find(kitchens, { id: kitchenId });
                          setKitchen({ kitchen });
                          setFieldValue("profiles", [], true);
                          setFieldTouched("profiles", false);
                          setDirty(true);
                        }}
                        onBlur={handleBlur}
                      >
                        {sortBy(kitchens, (kitchen) =>
                          toLower(kitchen.name),
                        ).map((kitchen) => (
                          <MenuItem key={kitchen.id} value={kitchen.id}>
                            {kitchen.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors.kitchenId && touched.kitchenId
                          ? errors.kitchenId
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item className={classes.row}>
                    <FormControl className={classes.formControl}>
                      <Box display="flex">
                        <TextField
                          id="profiles"
                          label={t("profiles")}
                          name="profiles"
                          disabled={
                            !online ||
                            !Object.keys(appSettings.kitchen).includes("id")
                          }
                          value={join(map(values.profiles, "name"), ", ")}
                          error={errors.profiles && touched.profiles}
                          autoComplete="off"
                          onClick={() => {
                            if (appSettings.kitchen.id && online)
                              handleOpenProfileDialog();
                          }}
                          InputProps={{
                            readOnly: true,
                            classes: {
                              root: classes.field,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <ArrowDropDownIcon color="action" />
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            classes: {
                              shrink: classes.shrink,
                            },
                          }}
                          className={classes.profiles}
                          helperText={
                            errors.profiles && touched.profiles
                              ? errors.profiles
                              : ""
                          }
                        />
                        <ProfileDialog
                          open={open}
                          profiles={profiles}
                          selectedProfiles={values.profiles}
                          handleClose={() => setOpen(false)}
                          setProfiles={(selectedProfiles) => {
                            setProfiles({ profiles: selectedProfiles });
                            setFieldValue("profiles", selectedProfiles, true);
                            setDirty(true);
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>
                  <Grid item className={classes.row}>
                    <FormControl className={classes.formControl}>
                      <InputLabel>{t("unitSystem")}</InputLabel>
                      <Select
                        name="unitSystem"
                        value={values.unitSystem}
                        className={classes.field}
                        error={errors.unitSystem && touched.unitSystem}
                        onChange={(event) => {
                          handleChange(event);
                          setUnitSystem({ unitSystem: event.target.value });
                          setDirty(true);
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem key="imperial" value="imperial">
                          {t("imperial")}
                        </MenuItem>
                        <MenuItem key="metric" value="metric">
                          {t("metric")}
                        </MenuItem>
                      </Select>
                      <FormHelperText>
                        {errors.unitSystem && touched.unitSystem
                          ? errors.unitSystem
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item className={classes.row}>
                    <FormControl className={classes.formControl}>
                      <InputLabel>{t("language")}</InputLabel>
                      <Select
                        name="language"
                        value={i18n.language}
                        className={classes.field}
                        error={errors.language && touched.language}
                        onChange={(event) => {
                          handleChange(event);
                          i18n.changeLanguage(event.target.value);
                          localStorage.setItem("lng", event.target.value);
                          setDirty(true);
                        }}
                        onBlur={handleBlur}
                      >
                        {LANGUAGES.map((lang) => (
                          <MenuItem key={lang} value={lang}>
                            {t(`languages:${lang}`)}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        {errors.language && touched.language
                          ? errors.language
                          : ""}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item className={classes.row}>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="tabletId"
                        disabled
                        label={t("tabletId")}
                        value={values.tabletId}
                        InputProps={{
                          classes: {
                            root: classes.field,
                          },
                          readOnly: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item className={classes.row}>
                    <FormControl className={classes.formControl}>
                      <Box display="flex" flexDirection="row-reverse">
                        <Button
                          type="submit"
                          disabled={
                            submitting ||
                            !online ||
                            !dirty ||
                            !values.kitchenId ||
                            !values.profiles.length
                          }
                        >
                          {online ? t("submit") : t("online_required")}
                          {submitting && (
                            <CircularProgress
                              className={classes.spinner}
                              size={20}
                            />
                          )}
                        </Button>
                      </Box>
                    </FormControl>
                  </Grid>
                </Grid>
              </Form>
            </ThemeProvider>
          );
        }}
      </Formik>
      <Dialog open={!online}>
        <Box className={classes.connectionErrorDialogBox}>
          <Typography className={classes.connectionErrorTitle}>
            {t("connectionErrorTitle")}
          </Typography>
          <Typography className={classes.connectionErrorMessage}>
            {t("connectionErrorMessage")}
          </Typography>
          <Typography className={classes.connectionErrorMessage}>
            <span>{t("connectionErrorBackHome1")}</span>
            <ButtonBase
              onClick={() => navigate("/app")}
              className={classes.connectionErrorButton}
            >
              {t("connectionErrorBackHome2")}
            </ButtonBase>
          </Typography>
        </Box>
      </Dialog>
    </Layout>
  );
};

Settings.propTypes = {
  resetWasteEntry: PropTypes.func.isRequired,

  // all of the kitchens for the unit (from settings)
  fetchKitchens: PropTypes.func.isRequired,
  fetchKitchensResult: PropTypes.shape({
    kitchens: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),

  // profiles for kitchen
  fetchProfiles: PropTypes.func.isRequired,
  fetchProfilesResult: PropTypes.shape({
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),

  // update settings with choices
  setKitchen: PropTypes.func.isRequired,
  setProfiles: PropTypes.func.isRequired,
  setUnitSystem: PropTypes.func.isRequired,

  // the chosen unit, kitchen and profiles
  appSettings: PropTypes.shape({
    unit: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }).isRequired,
    kitchen: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    tabletId: PropTypes.number.isRequired,
  }),
};

export default Settings;
