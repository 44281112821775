import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TouchRadio from '~/common/TouchRadio';

const svgTriangle = (fillColor, width, height) => `
  <svg xmlns="http://www.w3.org/2000/svg" height="${height * 2}" width="${width * 2}" fill="${fillColor}" viewBox="0 0 ${width} ${height}">
    <path stroke="#ffffff" stroke-width="1" stroke-linecap="square" d="M0 0 L${width} 0 L${width / 2} ${height} Z" />
  </svg>`;

const useStyles = (fillColor, width, height, opacity = 1) => makeStyles(theme => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: `${width * 2}px`,
    height: `${height * 2}px`,
    backgroundImage: `url(data:image/svg+xml;base64,${btoa(svgTriangle(fillColor, width, height))})`,
    'input:hover ~ &': {
      backgroundImage: `url(data:image/svg+xml;base64,${btoa(svgTriangle(theme.palette.primary.main, width, height))})`,
    },
    'input:disabled ~ &': {
      backgroundImage: `url(data:image/svg+xml;base64,${btoa(svgTriangle('rgba(206,217,224,.5)', width, height))})`,
    },
  },
  label: {
    position: 'absolute',
    padding: 20,
    textAlign: 'center',
    width: `${width * 2}px`,
    color: theme.palette.primary.contrastText,
  },
  title: {
    textTransform: 'uppercase',
    lineHeight: '22px',
    marginBottom: '5px'
  },
  caption: {
    fontSize: '1.08rem',
    textTransform: 'capitalize'
  },
  labelRoot: {
    margin: 0,
    alignItems: 'flex-start',
    opacity,
  },
  radioRoot: {
    padding: 0,
  },
}));

const StyledRadio = ({
  fillColor, width, height, ...other
}) => {
  const classes = useStyles(fillColor, width, height)();

  return (
    <TouchRadio
      classes={{ root: classes.radioRoot }}
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={classes.icon} />}
      icon={<span className={classes.icon} />}
      {...other}
    />
  );
};

StyledRadio.propTypes = {
  fillColor: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

const MultilineLabel = ({ title, caption }) => {
  const classes = useStyles()();

  return (
    <div >
      <Typography variant="caption" className={classes.title}>{title}</Typography>
      <div className={classes.caption}>{caption}</div>
    </div>
  );
};

MultilineLabel.propTypes = {
  title: PropTypes.string.isRequired,
  caption: PropTypes.string,
};

MultilineLabel.defaultProps = {
  caption: '',
};


const TriangleRadio = ({
  title, caption, value, fillColor, opacity, width, ...other
}) => {
  const height = (width / 2) * Math.tan(60 * (Math.PI / 180)); // ~130
  const classes = useStyles(fillColor, width, height, opacity)();
  return (
    <FormControlLabel
      classes={{ label: classes.label, root: classes.labelRoot }}
      value={value}
      control={<StyledRadio fillColor={fillColor} width={width} height={height} {...other} />}
      label={<MultilineLabel title={title} caption={caption} />}
    />
  );
};

TriangleRadio.defaultProps = {
  width: 120,
};

TriangleRadio.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  fillColor: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
  width: PropTypes.number,
};

export default TriangleRadio;
