import fetch from "isomorphic-fetch";
import { put, call, takeLatest } from "redux-saga/effects";
import logger from "debug";
import { activate } from "../actions";
import { setUnit, setTabletId } from "~/Settings/actions";
import { baseApiUrl } from "~/utils/apiHelpers";

const doFetch = ({ activationCode }) =>
  fetch(`${baseApiUrl}/activate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ activation_code: activationCode }),
  })
    .then((response) => {
      if (response.status === 200) return response.json();
      else if (response.status === 401 || response.status === 422)
        throw new Error("Invalid activation code");
      else throw new Error("Unexpected error");
    })
    .then((data) => data)
    .catch((error) => {
      logger("wastenot:error")("Error activating: ", error);
      throw error;
    });


function* activateWorker({ payload: { activationCode } }) {
  try {
    yield put(activate.request());
    const data = yield call(doFetch, { activationCode });
    yield put(activate.success(data));
    const { unit, tablet_id: tabletId } = data;
    yield put(setUnit({ unit }));
    yield put(setTabletId({ tabletId }));
  } catch (error) {
    const message = `Error activating tablet: ${error}`;
    logger("wastenot:error")(message);
    yield put(activate.failure({ error: message }));
  }
}

function* activateWatcher() {
  yield takeLatest(activate.TRIGGER, activateWorker);
}

export default [activateWatcher];
