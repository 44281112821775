import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import { navigate } from "@reach/router";
import { Layout } from "~/Layout";
import getCrumbs from "~/Layout/crumbs";
import overrides from "~/Theme/overrides";
import { useDispatch, useSelector } from "react-redux";
import { activate } from "../actions";
import { showAppMessage } from "~/AppMessage/actions";
import { setUnitSystem } from "~/Settings/actions";

const useStyles = makeStyles(() => ({
  form: {
    height: "100%",
  },
  container: {
    height: "100%",
  },
  formControl: {
    width: "80vw",
  },
  row: {
    marginLeft: "10vw",
  },
  codeField: {
    fontSize: "2rem",
    "&::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  continue: {
    paddingRight: 30,
  },
  continueIcon: {
    position: "absolute",
    right: 0,
  },
  spinner: {
    position: "absolute",
  },
}));

const theme = createTheme({
  overrides: {
    ...overrides,
    // double the size of the initial select input label
    MuiInputLabel: {
      formControl: {
        transform: "translate(0, 10px) scale(2)",
      },
    },
  },
});

const Activation = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation("activation_page");
  const dispatch = useDispatch();

  const validate = (values) => {
    const errors = {};

    if (!values.activationCode) {
      errors.activationCode = t("required");
    }

    return errors;
  };

  const { status, error, unit } = useSelector((state) => state.activate);
  useEffect(() => {
    if (status === "success") {
      // todo: if settings already complete, navigate to home
      const defaultLanguage = unit.language.code;
      i18n.changeLanguage(defaultLanguage);
      localStorage.setItem("lng", defaultLanguage);

      const defaultUnitSystem = unit.system;
      dispatch(setUnitSystem({ unitSystem: defaultUnitSystem }));

      navigate("/settings");
    }
  }, [status, unit, i18n, dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(
        showAppMessage({
          variant: "warning",
          duration: 3000,
          message: t("invalid"),
        }),
      );
    }
  }, [error, dispatch, t]);

  return (
    <Layout crumbs={getCrumbs("home/activation")}>
      <Formik
        initialValues={{
          activationCode: "",
        }}
        validate={validate}
        onSubmit={({ activationCode }) => {
          dispatch(activate({ activationCode }));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isValidating,
        }) => (
          <ThemeProvider theme={theme}>
            <Form noValidate className={classes.form}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                className={classes.container}
                spacing={1}
              >
                <Grid item className={classes.row}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="activationCode"
                      label={t("activation_code")}
                      value={values.activationCode}
                      type="text"
                      error={errors.activationCode && touched.activationCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormHelperText>
                      {errors.activationCode && touched.activationCode
                        ? errors.activationCode
                        : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item className={classes.row}>
                  <FormControl className={classes.formControl}>
                    <Box display="flex" flexDirection="row-reverse">
                      <Button
                        type="submit"
                        disabled={isValidating}
                        className={classes.continue}
                      >
                        {t("submit")}
                        <ChevronRightIcon className={classes.continueIcon} />
                        {isValidating && (
                          <CircularProgress
                            className={classes.spinner}
                            size={20}
                          />
                        )}
                      </Button>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          </ThemeProvider>
        )}
      </Formik>
    </Layout>
  );
};

export default Activation;
