import {
  createRoutineCreator,
  defaultRoutineStages,
} from "redux-saga-routines";

const activate = createRoutineCreator([...defaultRoutineStages, "RESET"])(
  "ACTIVATE"
);

export { activate };

