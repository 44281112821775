/* global BUILD_INFO */
import React from "react";
import PropTypes from "prop-types";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import GavelIcon from "@material-ui/icons/Gavel";
import HomeIcon from "@material-ui/icons/Home";
import HelpIcon from "@material-ui/icons/Help";
import ReplayIcon from "@material-ui/icons/Replay";
import CachedIcon from "@material-ui/icons/Cached";
import { Location, navigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { includes } from "lodash";
import fetchWaitingToSyncCount from "~/App/sync";
import { DateTime } from "luxon";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  listContainer: {
    width: 250,
    height: "100%",
  },
  list: {
    height: "100%",
  },
  appVersion: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  appVersionDivider: {
    color: theme.palette.text.secondary,
  },
}));

const Sidebar = ({ lastSyncDate, toggleDrawer, open }) => {
  const classes = useStyles();
  const [offline, setOffline] = useState(true);
  const { t } = useTranslation("sidebar");
  const [waitingToSyncCount, setWaitingToSyncCount] = React.useState(0);

  // only show settings for certain paths
  const settingsEnabledPaths = ["/app", "/privacy", "/settings", "/help"];
  const showSettings = (path) => includes(settingsEnabledPaths, path);

  // update sync count
  fetchWaitingToSyncCount(setWaitingToSyncCount);
  
  const handleConnectionChange = (event) => {
    setOffline(event.type !== "online");
  };

  React.useEffect(() => {
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    if (navigator.onLine) {
      setOffline(false);
    }

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  });


  return (
    <Drawer open={open} onClose={toggleDrawer(false)}>
      <div
        className={classes.listContainer}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List className={classes.list}>
          <ListItem button key={t("home")} onClick={() => navigate("/app")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={t("home")} />
          </ListItem>
          <Divider />
          <Location>
            {({ location }) =>
              showSettings(location.pathname) && (
                <ListItem
                  button
                  key={t("settings")}
                  onClick={() => navigate("/settings")}
                >
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("settings")} />
                </ListItem>
              )
            }
          </Location>
          <ListItem
            button
            key={t("privacy")}
            onClick={() => navigate("/privacy")}
          >
            <ListItemIcon>
              <GavelIcon />
            </ListItemIcon>
            <ListItemText primary={t("privacy")} />
          </ListItem>
          <ListItem button key={t("help")} onClick={() => navigate("/help")}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary={t("help")} />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={t("last_sync")}
              secondary={
                lastSyncDate
                  ? DateTime.fromMillis(lastSyncDate).toLocaleString(
                      DateTime.DATETIME_MED,
                    )
                  : t("n/a")
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={t("awaiting_sync")}
              secondary={t("waiting_to_sync_count", { waitingToSyncCount })}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Retry sync"
                onClick={() =>
                  window.dispatchEvent(new Event("REPLAY_REQUESTS"))
                }
                disabled={!waitingToSyncCount}
              >
                <ReplayIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary={t("reload")} />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="Reload app"
                onClick={() => document.location.reload()}
                disabled={offline || !!waitingToSyncCount}
              >
                <CachedIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {
            // keep as last item, positioned to bottom
          }
          <ListItem className={classes.appVersion}>
            <ListItemText
              primary={
                <div>
                  Version {BUILD_INFO.version}{" "}
                  <span className={classes.appVersionDivider}>|</span> Build{" "}
                  {BUILD_INFO.buildNumber}
                </div>
              }
              secondary={DateTime.fromISO(BUILD_INFO.buildDate).toLocaleString(
                DateTime.DATETIME_MED,
              )}
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

Sidebar.defaultProps = {
  lastSyncDate: 0,
  open: false,
};

Sidebar.propTypes = {
  lastSyncDate: PropTypes.number,
  toggleDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default Sidebar;
