const DESTINATIONS = [
  {
    color: '#254476',
    slug: 'FEED_HUNGRY_PEOPLE',
    submit: 'FEED_HUNGRY_PEOPLE',
  },
  {
    color: '#347462',
    slug: 'FEED_ANIMALS',
    submit: 'FEED_ANIMALS',
  },
  {
    color: '#D9AE58',
    slug: 'INDUSTRIAL_USES',
    submit: 'INDUSTRIAL_USES',
  },
  {
    color: '#EF8C31',
    slug: 'COMPOSTINGS',
    submit: 'COMPOSTINGS',
  },
  {
    color: '#9E5339',
    slug: 'INCINERATION',
    submit: 'INCINERATION',
  },
];

export default DESTINATIONS;
