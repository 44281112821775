import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { toLower, get } from 'lodash';
import RadioControl from '~/common/RadioControl';
import { Layout } from '~/Layout';
import getCrumbs from '~/Layout/crumbs';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  formControl: {
    display: 'flex',
    marginTop: 30,
  }
}));

const kinds = [
  {
    slug: 'RED_KITCHEN_WASTE',
    variant: 'default',
  },
  {
    slug: 'TRIM_BONES_SHELLS',
    variant: 'green',
  },
  {
    slug: 'CATERING_LEFTOVERS',
    variant: 'default',
  },
  {
    slug: 'PLATE_WASTE',
    variant: 'default',
  },
];

const KindOfWaste = ({
  selectKind, kind, profile: { kindsOfWaste }, submitDelay,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('kind_of_waste_page');

  const handleChange = (event) => {
    const { value } = event.target;
    selectKind({ kind: value });
    setTimeout(() => navigate(value === 'RED_KITCHEN_WASTE' ? '/whyTossed' : '/destination'), submitDelay);
  };

  const radio = (slug, variant) => (
    <RadioControl
      title={t(`buttons.${slug}.title`)}
      caption={t(`buttons.${slug}.caption`)}
      onClick={handleChange}
      value={slug}
      name="kind"
      checked={kind === slug}
      inputProps={{ 'aria-label': t(`buttons.${slug}.title`) }}
      disabled={!get(kindsOfWaste, toLower(slug))}
      variant={variant}
    />
  );

  return (
    <Layout crumbs={getCrumbs('home/kindOfWaste')} previousScreen="/app">
      <Box className={classes.container}>
        <Typography variant="h2" align="center">{t('title')}</Typography>
        <FormControl component="fieldset" className={classes.formControl}>
          <RadioGroup aria-label={t('short_title')} name="kindOfWaste">
            <Grid container direction="row" align="center" justifyContent="center" spacing={1}>
              {kinds.map(({ slug, variant }) => (
                <Grid item xs={12} sm={6} key={slug}>{radio(slug, variant)}</Grid>
              ))}
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
    </Layout>
  );
};

KindOfWaste.defaultProps = {
  kind: '',
};

KindOfWaste.propTypes = {
  kind: PropTypes.string,
  selectKind: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    kindsOfWaste: PropTypes.shape({
      red_kitchen_waste: PropTypes.bool.isRequired,
      trim_bones_shells: PropTypes.bool.isRequired,
      catering_leftovers: PropTypes.bool.isRequired,
      plate_waste: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  submitDelay: PropTypes.number.isRequired,
};

export default KindOfWaste;
