import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import {
  find, toLower,
} from 'lodash';
import TriangleRadio from './TriangleRadio';
import TrapezoidRadio from './TrapezoidRadio';
import { Layout } from '~/Layout';
import getCrumbs from '~/Layout/crumbs';
import RadioControl from '~/common/RadioControl';
import DESTINATIONS from '../destinations';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    overflow: 'auto',
    position: 'relative',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 45,
  },
  title: {
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: 10,
      left: 30,
    },
  },
}));

// compute the max width (ie top) of the trapezoid based on the smaller dimension (ie bottom)
const getWidth = (base, height) => {
  const tail = height / Math.tan(60 * (Math.PI / 180)); // ~43.3 for 50
  return base + (2 * tail);
};

const Destination = ({
  selectDestination, destination, kind, profile: { destinations }, submitDelay,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('destination_page');
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));
  
  const isDisabled = (dest) => {
    return !find(destinations, { slug: toLower(dest) }).enabled;
  }

  const handleChange = (event) => {
    // NB. disabled state occurs after click
    // check profile for disabled destinations
    if (event.target.parentElement) {
      event.target.parentElement.style.opacity = 0.3;
    }
    const dest = event.target.value;
    if (isDisabled(dest)) {
      return;
    } else {
      // select it
      selectDestination({ destination: event.target.value });
      setTimeout(() => navigate('/container'), submitDelay);
    }
  };

  // for xs layout, standard radio behaviour
  const radio = (destSlug) => {
    const disabled = isDisabled(destSlug);
    return (
      <RadioControl
        title={t(`buttons.${destSlug}.title`)}
        caption={disabled ? t("not_available") : ''}
        onClick={handleChange}
        value={destSlug}
        name="destination"
        checked={destination === destSlug}
        inputProps={{ 'aria-label': t(`buttons.${destSlug}.title`) }}
        disabled={disabled}
      />
    );
  };

  // these are radios with non-standard behaviour
  // when a choice is made we will change *the other* radios

  const trapezoid = (destSlug, width) => {
    const disabled = isDisabled(destSlug);
    return (
      <TrapezoidRadio
        title={t(`buttons.${destSlug}.title`)}
        caption={disabled ? t("not_available") : ''}
        onClick={handleChange}
        value={destSlug}
        name="destination"
        opacity={1}
        checked={destination === destSlug}
        inputProps={{ 'aria-label': t(`buttons.${destSlug}.title`) }}
        width={width}
        height={44}
        fillColor={find(DESTINATIONS, { slug: destSlug }).color}
        disabled={disabled}
      />
    );
  };

  // todo: build the crumbs incrementally and store the path, to get the previous screen correct
  // then we won't need previous screen anymore
  const previousScreen = kind === 'RED_KITCHEN_WASTE' ? '/whyTossed' : '/kindOfWaste';

  // this is the width of the triangle == smallest width of smallest trapezoid
  const baseWidth = 130;

  // height of each trapezoid
  const height = 44;

  return (
    <Layout crumbs={getCrumbs('home/kindOfWaste/whyTossed/destination')} previousScreen={previousScreen}>
      <Box className={classes.container}>
        <Typography variant="h2" align="center" className={classes.title}>{t('title')}</Typography>
        <FormControl component="fieldset">
          <RadioGroup aria-label={t('short_title')} name="destination">
            { xs && (
              <Grid container direction="row" align="center" justifyContent="center" spacing={1}>
                {DESTINATIONS.map(dest => (
                  <Grid item xs={12} sm={6} key={dest.slug}>
                    {radio(dest.slug)}
                  </Grid>
                ))}
              </Grid>
            )}
            { !xs && (
              <Grid container direction="row" align="center" justifyContent="center" spacing={0}>
                <Grid item xs={12}>
                  {trapezoid('FEED_HUNGRY_PEOPLE', getWidth(getWidth(getWidth(getWidth(baseWidth, height), height), height), height))}
                </Grid>
                <Grid item xs={12}>
                  {trapezoid('FEED_ANIMALS', getWidth(getWidth(getWidth(baseWidth, height), height), height))}
                </Grid>
                <Grid item xs={12}>
                  {trapezoid('INDUSTRIAL_USES', getWidth(getWidth(baseWidth, height), height))}
                </Grid>
                <Grid item xs={12}>
                  {trapezoid('COMPOSTINGS', getWidth(baseWidth, height))}
                </Grid>
                <Grid item xs={12}>
                  <TriangleRadio
                    title={t('buttons.INCINERATION.title')}
                    caption={isDisabled('INCINERATION') ? t("not_available") : ''}
                    onClick={handleChange}
                    value="INCINERATION"
                    name="destination"
                    checked={destination === 'INCINERATION'}
                    inputProps={{ 'aria-label': t('buttons.INCINERATION.title') }}
                    fillColor={find(DESTINATIONS, { slug: 'INCINERATION' }).color}
                    disabled={isDisabled('INCINERATION')}
                    opacity={1}
                    width={baseWidth}
                  />
                </Grid>
              </Grid>
            )}
          </RadioGroup>
        </FormControl>
      </Box>
    </Layout>
  );
};

Destination.defaultProps = {
  destination: '',
  kind: '',
};

Destination.propTypes = {
  destination: PropTypes.string,
  kind: PropTypes.string,
  selectDestination: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    destinations: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string.isRequired,
      enabled: PropTypes.bool.isRequired,
    })),
  }).isRequired,
  submitDelay: PropTypes.number.isRequired,
};

export default Destination;
