import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { sortBy, get, find, first, filter, isNil, isEmpty } from "lodash";
import { navigate, globalHistory } from "@reach/router";
import { useTranslation } from "react-i18next";
import { Layout } from "~/Layout";
import getCrumbs from "~/Layout/crumbs";
import { LANGUAGES } from "~/i18n";

const useStyles = makeStyles(() => ({
  form: {
    height: "100%",
  },
  container: {
    height: "100%",
  },
  logo: {
    display: "block",
    height: 300,
    width: 300,
    backgroundImage: "url(/img/logo.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "contain",
  },
  profileContainer: {
    height: 50,
  },
  formControl: {
    minWidth: 200,
    margin: 10,
  },
  complete: {
    margin: 50,
  },
  field: {
    fontSize: "2rem",
  },
}));

const Home = ({
  appSettings,
  selectProfile,
  savedProfileId,
  saveProfileId,
  resetWasteEntry,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(["home_page", "languages"]);

  useEffect(() => {
    const handlePopstate = (event) => {
      globalHistory.navigate("/app", { replace: true });
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  // online/offline messaging
  const [online, setOnline] = React.useState(window.navigator.onLine);
  const handleConnectionChange = (event) => {
    setOnline(event.type === "online");
  };

  useEffect(() => {
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  });

  // app setup
  const profiles = filter(appSettings.profiles, (p) => !isNil(p));
  const settingsComplete = !isEmpty(profiles);

  // handle auto selection of profiles
  const getInitialProfileId = () => {
    const sortedProfiles = sortBy(profiles, "name");

    // if we have one saved and it exists
    if (savedProfileId && find(sortedProfiles, { id: savedProfileId })) {
      return savedProfileId;
    }

    // otherwise get the first profile
    return get(first(sortedProfiles), "id") || "";
  };

  // reset lang to saved value
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("lng") || "en");
  }, [i18n]);

  const formikRef = useRef();
  return (
    <Layout crumbs={getCrumbs("home")}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          profileId: getInitialProfileId(),
        }}
        onSubmit={(values) => {
          const profile = find(profiles, { id: values.profileId });

          // always clean the wasteEntry
          resetWasteEntry();

          // for the waste entry
          selectProfile({
            kitchen: appSettings.kitchen,
            profile,
          });

          navigate("/kindOfWaste");
        }}
        validationSchema={Yup.object().shape({
          profileId: Yup.string().required(t("profile_required")),
        })}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form noValidate className={classes.form}>
            <Grid
              container
              direction="column"
              align="center"
              justifyContent="center"
              className={classes.container}
            >
              <Grid item>
                {!settingsComplete && <span className={classes.logo} />}
                {settingsComplete && (
                  <ButtonBase
                    disableRipple
                    className={classes.logo}
                    onClick={() => formikRef.current.submitForm()}
                  />
                )}
              </Grid>
              <Grid item className={classes.profileContainer}>
                {settingsComplete && (
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                  >
                    <FormControl className={classes.formControl}>
                      <Select
                        name="profileId"
                        value={values.profileId}
                        displayEmpty
                        className={classes.field}
                        error={errors.profileId && touched.profileId}
                        onChange={(event) => {
                          handleChange(event);
                          saveProfileId({ profileId: event.target.value });
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="" disabled>
                          {t("choose_profile")}
                        </MenuItem>
                        {sortBy(profiles, "name").map((p) => (
                          <MenuItem key={p.id} value={p.id}>
                            {p.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{errors.profileId}</FormHelperText>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <Select
                        name="language"
                        value={i18n.language}
                        className={classes.field}
                        error={errors.language && touched.language}
                        onChange={(event) => {
                          handleChange(event);
                          i18n.changeLanguage(event.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem value="" disabled>
                          {t("choose_language")}
                        </MenuItem>
                        {LANGUAGES.map((lang) => (
                          <MenuItem key={lang} value={lang}>
                            {t(`languages:${lang}`)}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>{errors.language}</FormHelperText>
                    </FormControl>
                  </Box>
                )}
                {!settingsComplete && (
                  <Button
                    className={classes.complete}
                    onClick={() => navigate("/settings")}
                    disabled={!online}
                  >
                    {online ? t("complete_setup") : t("online_required")}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

Home.defaultProps = {
  savedProfileId: null,

  appSettings: {
    unit: {},
    kitchen: {},
    profiles: [], // subset
    tabletId: "",
  },
};

Home.propTypes = {
  resetWasteEntry: PropTypes.func.isRequired,
  selectProfile: PropTypes.func.isRequired,
  saveProfileId: PropTypes.func.isRequired,
  savedProfileId: PropTypes.number,

  appSettings: PropTypes.shape({
    unit: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
    kitchen: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    tabletId: PropTypes.number,
  }),
};

export default Home;
