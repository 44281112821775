/* global CONFIG */
import fetch from 'isomorphic-fetch';
import { put, call, takeLatest } from 'redux-saga/effects';
import logger from 'debug';
import { fetchBuildInfo } from '../actions';

const doFetch = url => (
  fetch(url)
    .then(response => response.json())
);

function* fetchBuildInfoWorker() {
  try {
    yield put(fetchBuildInfo.request());
    const buildInfo = yield call(doFetch, CONFIG.buildInfoUrl);
    yield put(fetchBuildInfo.success({ buildInfo }));
  } catch (error) {
    const message = `Error requesting app info: ${error}`;
    logger('wastenot:error')(message);
    yield put(fetchBuildInfo.failure({ error: message }));
  }
}

function* fetchBuildInfoWatcher() {
  yield takeLatest(fetchBuildInfo.TRIGGER, fetchBuildInfoWorker);
}

export default [
  fetchBuildInfoWatcher,
];
