import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import App from '../components/App';
import { setLastSync, fetchBuildInfo } from '../actions';
import { showAppMessage } from '~/AppMessage/actions';
import { setSubmitDelay } from '~/UserConfig/actions';

const mapStateToProps = ({
  activate: { access_token },
  fetchBuildInfo,
}) => ({
  fetchBuildInfoResult: fetchBuildInfo,
  access_token,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  setLastSync,
  showAppMessage,
  fetchBuildInfo,
  setSubmitDelay,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
