import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Slider from "@material-ui/core/Slider";
import AddIcon from "@material-ui/icons/Add";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styled from "styled-components";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { navigate } from "@reach/router";
import { useTranslation } from "react-i18next";
import { find, get } from "lodash";
import { Layout } from "~/Layout";
import getCrumbs from "~/Layout/crumbs";
import TouchButton from "~/common/TouchButton";
import Counter from "./Counter";
import { Redirect } from "@reach/router";

const StyledSlider = styled((props) => (
  <Slider
    classes={{
      rail: "rail",
      track: "track",
      thumb: "thumb",
      valueLabel: "valueLabel",
      mark: "mark",
    }}
    {...props}
  />
))`
  &&& .valueLabel {
    transform: scale(2) translateY(-10px) rotate(90deg);
    left: 110px;
    top: -5px;
  }
  &&& .valueLabel span span {
    transform: rotate(-45deg);
  }
  && .rail {
    width: 100px;
    background-color: #ddd;
  }
  && .track {
    width: 100px;
  }
  && .thumb {
    background-color: #888888;
    width: 110px;
    border-radius: 5px;
    height: 20px;
  }
  && .mark {
    width: 100px;
  }
`;

const imageStyles = (theme, container) => {
  const styles = {};
  const allImages = container ? container.images : [];
  const imageURL = (i) => {
    return container ? find(allImages, { percentage: i * 10 }).url : "";
  };
  const baseUrl = container ? container.image_base_url : "";
  for (let i = 0; i <= 10; i += 1) {
    styles[`image${i * 10}`] = {
      height: 420,
      width: 420,
      backgroundImage: `url(${baseUrl}/${imageURL(i)})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "contain",
      margin: "0 20px",
      [theme.breakpoints.down("740")]: {
        margin: "auto",
      },
    };
  }
  return styles;
};
const useStyles = (baseImage) =>
  makeStyles((theme) => ({
    container: {
      overflow: "hidden",
      paddingLeft: 30,
      paddingRight: 30,
      paddingTop: 20,
    },
    sliderWrapper: {
      height: "65vh",
      position: "relative",
      left: 0,
      top: 0,
      [theme.breakpoints.up("md")]: {
        left: -80,
      },
      [theme.breakpoints.up("lg")]: {
        height: "67vh",
        left: -120,
        top: -40,
      },
    },
    buttons: {
      position: "relative",
    },
    buttonContainer: {
      position: "absolute",
      top: -120,
      flexDirection: "column",
      right: 10,
      height: 150,
      [theme.breakpoints.up("lg")]: {
        top: -180,
      },
      [theme.breakpoints.between("600", "800")]: {
        right: 60,
      },
    },
    smButtonContainer: {
      flexDirection: "column",
    },
    lgSubmit: {
      minHeight: 65,
      width: "100%",
      [theme.breakpoints.up("md")]: {
        paddingRight: 30,
        paddingLeft: 30,
      },
    },
    revealCounter: {
      minHeight: 65,
      marginBottom: 20,
      [theme.breakpoints.up("md")]: {
        width: "100%",
        paddingRight: 57,
        marginBottom: 20,
      },
    },
    continueIcon: {
      [theme.breakpoints.up("md")]: {
        right: -10,
      },
    },
    addIcon: {
      [theme.breakpoints.up("md")]: {
        position: "absolute",
        right: 20,
      },
    },
    revealCounterText: {
      marginRight: 8,
    },
    imageContainer: {
      position: "relative",
      right: -90,
      padding: "0 !important",
    },
    counterContainer: {
      position: "relative",
      width: 270,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up("md")]: {
        right: -70,
      },
      [theme.breakpoints.between("740", "960")]: {
        right: 50,
      },
      [theme.breakpoints.up("lg")]: {
        right: -140,
      },
    },
    smLayout: {
      marginTop: 50,
      paddingBottom: 30,
    },
    xsSlider: {
      width: "100%",
      marginTop: 20,
    },
    valueLabel: {
      transform: "scale(2) translateY(-10px)",
      left: -6,
    },
    ...imageStyles(theme, baseImage),
  }));
const formatValue = (value) => `${value}%`;
const imageClassName = (percentage) => `image${percentage}`;
const Quantity = ({
  quantity,
  selectQuantity,
  container: selectedContainer,
  submitDelay,
}) => {
  const { t } = useTranslation("quantity_page");

  const shouldRedirect =
    !selectedContainer ||
    !selectedContainer.images ||
    selectedContainer.images.length === 0;

  const [percentage, setPercentage] = React.useState(
    get(quantity, "percentage"),
  );
  const [count, setCount] = React.useState(get(quantity, "count"));
  const [showCount, setShowCount] = React.useState(
    get(quantity, "count") !== 1,
  );

  const classes = useStyles(selectedContainer)();

  const submit = () => {
    selectQuantity({
      type: "percentage",
      percentage,
      count,
    });
    setTimeout(() => navigate("/typeOfWaste"), submitDelay);
  };

  const handleChange = () => {
    selectQuantity({
      type: "percentage",
      percentage,
      count,
    });
  };

  const handleButtonPress = (dir) => {
    setCount((ct) => Math.min(Math.max(ct + dir, 1), 99));
  };

  const revealCounter = () => setShowCount(true);

  const theme = useTheme();
  const xs = !useMediaQuery(theme.breakpoints.up(740));
  const counterContainer = (
    <Grid item className={classes.counterContainer}>
      {showCount && (
        <Counter count={count} handleButtonPress={handleButtonPress} />
      )}
    </Grid>
  );

  const countButton = !showCount && (
    <TouchButton
      onClick={revealCounter}
      disableRipple
      disabled={percentage === 0}
      className={classes.revealCounter}
    >
      {t("container_count")}
      <AddIcon className={classes.addIcon} />
    </TouchButton>
  );

  const smLayout = (
    <Grid container direction="column" className={classes.smLayout}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={10}
        wrap="nowrap"
      >
        <Grid item>
          <div className={classes.sliderWrapper}>
            <StyledSlider
              orientation="vertical"
              value={percentage}
              aria-label={t("quantity_by_percentage")}
              getAriaValueText={formatValue}
              step={10}
              marks
              valueLabelDisplay="on"
              valueLabelFormat={formatValue}
              onChange={(event, value) => setPercentage(value)}
              onChangeCommitted={handleChange}
              min={0}
            />
          </div>
        </Grid>
        <Grid item className={classes.imageContainer}>
          <div className={classes[imageClassName(percentage)]} />
        </Grid>
        {counterContainer}
      </Grid>
      <Grid item className={classes.buttons}>
        <Box display="flex" className={classes.buttonContainer}>
          {countButton}
          <TouchButton
            onClick={submit}
            disableRipple
            className={classes.lgSubmit}
            disabled={percentage === 0}
          >
            {t("continue")}
            <ChevronRightIcon className={classes.continueIcon} />
          </TouchButton>
        </Box>
      </Grid>
    </Grid>
  );

  const xsLayout = (
    <Grid container direction="row" justifyContent="center" spacing={1}>
      <Grid item xs={12} className={classes.xsSlider}>
        <Slider
          orientation="horizontal"
          value={percentage}
          aria-label={t("quantity_by_percentage")}
          getAriaValueText={formatValue}
          step={10}
          marks
          valueLabelDisplay="on"
          valueLabelFormat={formatValue}
          onChange={(event, value) => setPercentage(value)}
          onChangeCommitted={handleChange}
          min={10}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes[imageClassName(percentage)]} />
      </Grid>
      {counterContainer}
      <Grid item xs={12}>
        <Box
          mt={2}
          display="flex"
          justifyContent="center"
          className={classes.smButtonContainer}
        >
          {countButton}
          <TouchButton
            onClick={submit}
            disabled={percentage === 0}
            disableRipple
            className={classes.lgSubmit}
          >
            {t("continue")}
            <ChevronRightIcon />
          </TouchButton>
        </Box>
      </Grid>
    </Grid>
  );

  return shouldRedirect ? (
    <Redirect to="/app" noThrow />
  ) : (
    <Layout
      crumbs={getCrumbs(
        "home/kindOfWaste/whyTossed/destination/container/quantity",
      )}
      previousScreen="/container"
    >
      <Box className={classes.container}>
        <Typography variant="h2" align="center">
          {showCount ? t("counter_title") : t("title")}
        </Typography>
        {xs ? xsLayout : smLayout}
      </Box>
    </Layout>
  );
};

Quantity.propTypes = {
  // default props don't work as expected for objects, must specify in duck
  quantity: PropTypes.shape({
    percentage: PropTypes.number,
    count: PropTypes.number,
  }).isRequired,
  selectQuantity: PropTypes.func.isRequired,
  container: PropTypes.shape({
    images: PropTypes.arrayOf(
      PropTypes.shape({
        percentage: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  submitDelay: PropTypes.number.isRequired,
};

export default Quantity;
