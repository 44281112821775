import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

const fetchKitchens = createRoutine('FETCH_KITCHENS');
const fetchProfiles = createRoutine('FETCH_PROFILES');

const setUnit = createAction('SET_UNIT');
setUnit.TYPE = setUnit.toString();

const setKitchen = createAction('SET_KITCHEN');
setKitchen.TYPE = setKitchen.toString();

const setProfiles = createAction('SET_PROFILES');
setProfiles.TYPE = setProfiles.toString();

const setUnitSystem = createAction('SET_UNIT_SYSTEM');
setUnitSystem.TYPE = setUnitSystem.toString();

const setTabletId = createAction('SET_TABLET_ID');
setTabletId.TYPE = setTabletId.toString();

const resetSettings = createAction('RESET_SETTINGS');
resetSettings.TYPE = resetSettings.toString();

export {
  fetchKitchens,
  fetchProfiles,
  setUnit,
  setKitchen,
  setProfiles,
  setUnitSystem,
  setTabletId,
  resetSettings,
};
