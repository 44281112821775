/* global BUILD_INFO, CONFIG */
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import logger from "debug";
import compareVersions from "compare-versions";
import PageVisibility from "react-page-visibility";
import fetchWaitingToSyncCount from "~/App/sync";
import { navigate } from "@reach/router";
import { isNil } from "lodash";
import { DateTime } from "luxon";

const isAppUpdateAvailable = ({ version, buildDate }) => {
  switch (compareVersions(version, BUILD_INFO.version)) {
    case 0:
      return buildDate > BUILD_INFO.buildDate;
    case 1:
      return true;
    default:
      return false;
  }
};

const App = ({
  children,
  setLastSync,
  showAppMessage,
  access_token,
  fetchBuildInfo,
  fetchBuildInfoResult,
  setSubmitDelay,
}) => {
  const { t } = useTranslation("app");

  const handleOnLoad = () => {
    // reset userConfig
    setSubmitDelay({ submitDelay: 250 });

    // in some cases they may have leftover entries to sync
    fetchWaitingToSyncCount((count) => {
      if (count) {
        window.dispatchEvent(new Event("REPLAY_REQUESTS"));
      }
    });
  };

  const handleSyncSuccess = (event) => {
    setLastSync({ lastSyncDate: event.detail.date });
    showAppMessage({
      variant: "success",
      duration: 2000,
      message: t("sync.success"),
    });
  };

  const handleSyncError = () => {
    showAppMessage({
      variant: "warning",
      duration: 2000,
      message: t("sync.error"),
    });
  };

  const handleConnectionChange = (event) => {
    if (event.type === "online") {
      // check for new version
      fetchBuildInfo();

      // clear the api requests queue
      window.dispatchEvent(new Event("REPLAY_REQUESTS"));
    }
  };

  // delete the main.*.js cache items in the workbox-runtime cache
  const deleteCaches = async () => {
    logger("wastenot:debug")("Deleting cache entries.");
    const cacheNames = await window.caches.keys();
    const runtimeCache = cacheNames.find((cacheName) =>
      /^workbox-runtime/.test(cacheName),
    );
    const cache = await window.caches.open(runtimeCache);
    const requests = await cache.keys();
    const mainJsRequests = requests.filter((cacheRequest) =>
      /\/main\.[^.]+\.js$/.test(cacheRequest.url),
    );
    mainJsRequests.forEach((req) => {
      logger("wastenot:debug")("Deleting cache entry: ", req.url);
      cache.delete(req);
    });
  };

  // throughout dev and testing, we usually get the latest app and service worker
  //  because we close the tab, and fire it back up whenever
  // or we reload the page and check the version - we want to automate this
  // so in the Kiosk mode, with app forefront all the time, we're not really going
  //  to get foreground events
  // 3-pronged approach: react-page-visibility, check once every 30 minutes, and online event
  // if the server version is greater than the running version, reload the page, delete old caches
  const updateAppIfNeeded = async () => {
    // best to disable completely in local, otherwise how can you run against remote api eg. yarn start-staging?
    if (CONFIG.skipAppUpdateCheck) {
      logger("wastenot:info")("App update check is skipped");
      return;
    }
    const { buildInfo } = fetchBuildInfoResult;
    logger("wastenot:debug")("latest build: ", buildInfo);
    const { version, buildNumber, buildDate } = buildInfo;
    logger("wastenot:debug")("current build: ", BUILD_INFO);
    if (isAppUpdateAvailable({ version, buildDate })) {
      logger("wastenot:info")("Reloading the app.");
      showAppMessage({
        variant: "success",
        message: t("updated", {
          version: `${version} build ${buildNumber} date ${DateTime.fromISO(
            buildDate,
          ).toLocaleString(DateTime.DATETIME_MED)}`,
        }),
        duration: null,
      });
      await deleteCaches();
      setTimeout(() => document.location.reload(), 500);
    } else {
      logger("wastenot:info")("App is up to date.");
    }
  };

  const handleUncaughtError = (event) => {
    const { message, filename, lineno, colno, error } = event;
    if (message.toLowerCase().indexOf("script error") > -1) {
      alert(`Script Error: See Browser Console for Detail. ${message}.`);
    } else {
      alert(message, filename, lineno, colno, error);
    }
    return false;
  };

  // If we don't have a token, send the user to the token page--that's the only
  // thing they're allowed to do.
  if (isNil(access_token)) {
    navigate("/activation");
  }

  if (fetchBuildInfoResult.status === "success") {
    updateAppIfNeeded();
  }

  React.useEffect(() => {
    window.addEventListener("sync-complete", handleSyncSuccess);
    window.addEventListener("sync-error", handleSyncError);
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("error", handleUncaughtError);
    window.addEventListener("load", handleOnLoad);

    // 30 mins
    const appCheckTimer = setInterval(() => fetchBuildInfo(), 30 * 60 * 1000);

    return () => {
      window.removeEventListener("sync-complete", handleSyncSuccess);
      window.removeEventListener("sync-error", handleSyncError);
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("error", handleUncaughtError);
      window.removeEventListener("load", handleOnLoad);

      clearInterval(appCheckTimer);
    };
  });

  return (
    <PageVisibility
      onChange={(isVisible) => {
        if (isVisible) {
          fetchBuildInfo();
        }
      }}
    >
      {children}
    </PageVisibility>
  );
};

App.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  setLastSync: PropTypes.func.isRequired,
  showAppMessage: PropTypes.func.isRequired,
  fetchBuildInfo: PropTypes.func.isRequired,
  access_token: PropTypes.string,
  setSubmitDelay: PropTypes.func.isRequired,
  fetchBuildInfoResult: PropTypes.shape({
    status: PropTypes.string,
    buildInfo: PropTypes.shape({
      version: PropTypes.string,
      buildDate: PropTypes.string,
      buildNumber: PropTypes.string,
    }),
  }),
};

export default App;