import fetch from "isomorphic-fetch";
import { put, call, takeLatest, select } from "redux-saga/effects";
import { isNil, map, omit, filter, isEmpty, difference } from "lodash";
import logger from "debug";
import { setKitchen, fetchProfiles, resetSettings } from "../actions";
import { selectProfile } from "~/WasteEntry/actions";
import getAccessToken from "~/Activation/selectors";
import { baseApiUrl } from "~/utils/apiHelpers";
import { activate } from "~/Activation/actions";
import { navigate } from "@reach/router";
import { showAppMessage } from "~/AppMessage/actions";
import { getI18n } from "react-i18next";

const doFetch = (kitchenId, accessToken) =>
  fetch(`${baseApiUrl}/kitchens/${kitchenId}/profiles`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => {
      if (response.status === 401) {
        throw new Error("INVALID_ACCESS_TOKEN");
      }
      return response.json();
    })
    .then((profiles) =>
      map(profiles, (p) => {
        const containers = filter(
          p.containers,
          (container) => !isEmpty(container.fill_images),
        );
        const diff = difference(p.containers, containers);
        if (!isEmpty(diff)) {
          logger("wastenot:error")(
            `Some containers missing images (skipping) in ${p.name}:`,
            diff,
          );
        }
        return {
          id: p.id,
          name: p.name,
          destinations: map(p.waste_destinations, (enabled, slug) => ({
            slug,
            enabled,
          })),
          kindsOfWaste: p.kinds_of_waste,
          containers: containers.map((container) => ({
            ...omit(container, "fill_images"),
            images: container.fill_images.map((image) => ({
              percentage: image.perc,
              url: image.image,
            })),
          })),
        };
      }),
    );

function* fetchProfilesWorker(action) {
  yield put(fetchProfiles.request());
  const { kitchen, profile } = action.payload;
  const accessToken = yield select(getAccessToken);
  const t = (key) => getI18n().t(key, { ns: "activation_page" });

  try {
    const profiles = yield call(doFetch, kitchen.id, accessToken);

    if (!isNil(profiles)) {
      if (profile) {
        const matchingProfile = profiles.find(
          (_profile) => _profile.id === profile.id,
        );
        if (!matchingProfile) {
          throw new Error("PROFILE_DELETED");
        }
      }
      yield put(fetchProfiles.success({ profiles }));
    } else {
      // NB service worker will try cache first
      yield put(fetchProfiles.failure({ error: "Error requesting profiles" }));
    }
  } catch (e) {
    logger("wastenot:error")("Error fetching profiles: ", e);
    yield put(fetchProfiles.failure({ error: "Error requesting profiles" }));
    if (e.message === "INVALID_ACCESS_TOKEN") {
      yield put(activate.reset());
      yield put(
        showAppMessage({
          variant: "error",
          duration: 3000,
          message: t("invalid"),
        }),
      );
      navigate("/activation");      
    }
    if (e.message === "PROFILE_DELETED") {
      yield put(
        showAppMessage({
          variant: "error",
          duration: 3000,
          message: t("profile_deleted"),
        }),
      );
      yield put(resetSettings());
      navigate("/settings");
    }
  }
}

function* setKitchenWatcher() {
  yield takeLatest(setKitchen.TYPE, fetchProfilesWorker);
}

// when we select a profile on the home screen, re-fetch the profile
function* selectProfileWatcher() {
  yield takeLatest(selectProfile.TYPE, fetchProfilesWorker);
}

function* fetchProfilesWatcher() {
  yield takeLatest(fetchProfiles.TRIGGER, fetchProfilesWorker);
}

export default [setKitchenWatcher, selectProfileWatcher, fetchProfilesWatcher];
