import fetchKitchens from "./fetchKitchens";
import fetchProfiles from "./fetchProfiles";
import settings from "./settings";

const reducers = {
  fetchKitchens,
  fetchProfiles,
  settings,
};

export default reducers;
