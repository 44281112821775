/**
 * Global theme overrides.
 *
 * If you extend the theme for your component with its own overrides,
 * you must include these global ones too.
 */
import LatoRegular from 'Fonts/Lato-Regular.ttf';
import LatoMedium from 'Fonts/Lato-Medium.ttf';
import AgendaRegular from 'Fonts/agenda_regular.ttf';
import AgendaOneCondensed from 'Fonts/MIpes81D9Yu3bDAPkvPAKTEmAq4waeQtDrwuJZbx.app.ttf'
import NotoSansRegular from 'Fonts/NotoSans-Regular.ttf'

const latoRegular = {
  fontFamily: 'Lato Regular',
  fontStyle: 'normal',
  src: `
    local('Lato Regular'),
    url(${LatoRegular})
  `,
};

const notoSansRegular = {
  fontFamily: 'Noto Sans Regular',
  fontStyle: 'normal',
  src: `
    local('Noto Sans Regular'),
    url(${NotoSansRegular})
  `,
};

const agendaRegular = {
  fontFamily: 'Agenda Regular',
  fontStyle: 'normal',
  src: `
    local('Agenda Regular'),
    url(${AgendaRegular})
  `,
};

const latoMedium = {
  fontFamily: 'Lato Medium',
  fontStyle: 'normal',
  src: `
    local('Lato Medium'),
    url(${LatoMedium})
  `,
};

const agendaOneMedium = {
  fontFamily: 'Agenda One Comp Med',
  fontDisplay: 'swap',
  src: `
  local('Agenda One Comp Med'),
  url(${AgendaOneCondensed})
`,
}

export default {
  MuiCssBaseline: {
    '@global': {
      '@font-face': [latoRegular, latoMedium, agendaRegular, agendaOneMedium, notoSansRegular],
    },
  },
  MuiInputLabel: {
    formControl: {
      transform: 'translate(0, 30px) scale(2)',
    },
  },
  MuiButton: {
    root: {
      background: '#fff',
      borderRadius: 3,
      border: '2px solid #111',
      color: '#111',
      fontSize: 24,
      fontWeight: 600,
      fontFamily: 'Noto Sans Regular',
      '& [class^=MuiSvgIcon-root]': {
        fontSize: 34,
      },
      '& [class^=MuiButton-label]': {
        lineHeight: 1,
      },
      '& [class*=MuiButton-text]': {
        padding: '10px 8px',
      },
      '&:hover, &:focus, &:active': {
        background: '#111 !important',
        color: '#fff',
      },
      '&:disabled': {
        borderColor: '#ddd !important',
      },
      '& [class^=MuiTouchRipple-root]': {
        display: 'none',
      },
    },
  },
};
