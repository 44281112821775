import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  count: {
    position: 'relative',
    width: '60px',
    textAlign: 'center',
    top: '-18px',
  },
  containerCount: {
    fontSize: '3rem',
  },
}));

const Counter = ({ count, handleButtonPress }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} direction='column' alignItems='center'>
      <Grid item xs={12}>
        <Grid container spacing={1} wrap='nowrap'>
          <Grid item>
            <Fab
              color='primary'
              aria-label='minus'
              onClick={() => handleButtonPress(-1)}
            >
              <MinusIcon />
            </Fab>
          </Grid>
          <Grid item className={classes.count}>
            <Typography className={classes.containerCount} variant='h2'>
              {count}
            </Typography>
          </Grid>
          <Grid item>
            <Fab
              color='primary'
              aria-label='add'
              onClick={() => handleButtonPress(1)}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Counter.propTypes = {
  count: PropTypes.number.isRequired,
  handleButtonPress: PropTypes.func.isRequired,
};

export default Counter;
