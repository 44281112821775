import { fetchBuildInfo } from "../actions";

export const initialState = {
  status: "init",
  error: null,
  buildInfo: {
    version: null,
    buildNumber: null,
    buildDate: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case fetchBuildInfo.REQUEST: {
      return {
        ...state,
        error: null,
        status: "request",
      };
    }

    case fetchBuildInfo.SUCCESS: {
      return {
        ...state,
        status: "success",
        buildInfo: action.payload.buildInfo,
      };
    }

    case fetchBuildInfo.FAILURE: {
      return {
        ...state,
        status: "failure",
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
