import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TouchRadio from '~/common/TouchRadio';

const useStyles = image => makeStyles(theme => ({
  root: {
    position: 'relative',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    marginBottom:20
  },
  icon: {
    width: 200,
    height: 160,
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 220,
      height: 230,
      left: '-10px',
      top: '-10px',
      border: '3px solid transparent',
      borderRadius: 5,
    },
    'input:hover ~ &::after': {
      borderColor: 'black',
    },
  },
  checkedIcon: {
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    '&::after': {
      content: '""',
      position: 'absolute',
      width: 220,
      height: 220,
      left: '-10px',
      top: '-10px',
      border: `3px solid ${theme.palette.secondary.main}`,
      borderRadius: 5,
    },
    'input:hover ~ &::after': {
      borderColor: 'black',
    },
  },
  label: {
    position: 'absolute',
    padding: '5px 5px 10px 5px',
    marginTop: 165,
    textAlign: 'center',
    width: 200,
    height: 60,
    color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  title: {
    textTransform: 'capitalize',
  },
  labelRoot: {
    margin: 0,
    alignItems: 'flex-start',
  },
  radioRoot: {
    padding: 0,
  },
}));

const StyledRadio = ({ image, ...other }) => {
  const classes = useStyles(image)();

  return (
    <TouchRadio
      classes={{ root: classes.radioRoot }}
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...other}
    />
  );
};

StyledRadio.propTypes = {
  image: PropTypes.string.isRequired,
};

const ImageRadio = ({
  title, value, image, ...other
}) => {
  const classes = useStyles(image)();
  return (
    <FormControlLabel
      classes={{ label: classes.label, root: classes.labelRoot }}
      value={value}
      control={<StyledRadio image={image} {...other} />}
      label={<Typography variant="subtitle2" className={classes.title}>{title}</Typography>}
    />
  );
};

ImageRadio.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  image: PropTypes.string.isRequired,
};

export default ImageRadio;
