import map from 'lodash/map';
import { fork, all } from 'redux-saga/effects';
import settingsSagas from './Settings/sagas';
import wasteEntrySagas from './WasteEntry/sagas';
import appSagas from './App/sagas/index';
import activationSagas from  "./Activation/sagas";

const childSagas = [
  ...settingsSagas,
  ...wasteEntrySagas,
  ...appSagas,
  ...activationSagas
];

export default function* mainSaga() {
  yield all(map(childSagas, childSaga => (fork(childSaga))));
}
