import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';
import es from './es.json';
import de from './de.json';
import pt from './pt.json';
import it from './it.json';
import frCA from './fr-CA.json';
import tr from './tr.json';
import pl from './pl.json';
import uk from './uk.json';

export const LANGUAGES = ['en', 'de', 'es', 'fr', 'it', 'pt', 'frCA', 'tr', 'pl', 'uk'];

const i18n = (callback) => {
  i18next
    .use(initReactI18next)
    .init({
      lng: localStorage.getItem('lng') || 'en',
      resources: { en, de, es, fr, it, pt, frCA, tr, pl, uk },
      supportedLngs: LANGUAGES,
      fallbackLng: 'en',
      debug: true,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
    }, callback);
};

export default i18n;