import {
  setUnit, setKitchen, setProfiles, setTabletId, setUnitSystem, resetSettings
} from '../actions';

export const initialState = {
  app: {
    unit: {},
    kitchen: {},
    profiles: [],
    tabletId: null,
    unitSystem: 'imperial',
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case resetSettings.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          kitchen: {},
          profiles: [],
        },
      };
    }

    case setUnit.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          unit: action.payload.unit,
        },
      };
    }
    case setKitchen.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          kitchen: action.payload.kitchen,

          // reset
          profiles: [],
        },
      };
    }
    case setProfiles.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          profiles: action.payload.profiles,
        },
      };
    }
    case setTabletId.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          tabletId: action.payload.tabletId,
        },
      };
    }
    case setUnitSystem.TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          unitSystem: action.payload.unitSystem,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
