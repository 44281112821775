import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { find, get } from 'lodash';
import { Layout } from '~/Layout';
import getCrumbs from '~/Layout/crumbs';
import ImageRadio from './ImageRadio';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    overflow: 'auto',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  title: {
    textTransform: 'uppercase',
    marginTop: 20,
    marginBottom: 30,
  },
  fieldset: {
    marginTop: 50,
    width: '100%',
  },
  item: {
    // because icon titles are rendered absolute and need space
    marginBottom: 44,
  },
}));

const Container = ({
  container, selectContainer, resetQuantity, profile: { containers }, submitDelay,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('container_page');

  const handleChange = (event) => {
    const containerId = event.target.value * 1;
    if (containerId < 0) {
      selectContainer({ container: { id: containerId, name: 'EXACT_QUANTITY', volume: -1, unit: '' } });
      setTimeout(() => navigate('/exactQuantity'), submitDelay);
    } else {
      const selectedContainer = find(containers, { id: containerId });
      if (get(container, 'name') !== selectedContainer) {
        resetQuantity();
      }
      selectContainer({ container: selectedContainer });
      setTimeout(() => navigate('/quantity'), submitDelay);
    }
  };

  const baseImage = aContainer => (
    `${aContainer.image_base_url}/${find(aContainer.images, { percentage: 0 }).url}`
  );

  const radio = aContainer => (
    <ImageRadio
      title={aContainer.name}
      onClick={handleChange}
      value={aContainer.id}
      name="container"
      checked={container === aContainer.id}
      inputProps={{ 'aria-label': aContainer.name }}
      image={baseImage(aContainer)}
    />
  );

  const sm = (12 / Math.ceil((containers.length + 1) / 2));

  return (
    <Layout crumbs={getCrumbs('home/kindOfWaste/whyTossed/destination/container')} previousScreen="/destination">
      <Box className={classes.container}>
        <Typography variant="h2" align="center">{t('title')}</Typography>
        <FormControl component="fieldset" className={classes.fieldset}>
          <RadioGroup aria-label={t('short_title')} name="destination">
            <Grid container direction="row" align="center" justifyContent="center" spacing={3}>
              {containers
                .map(aContainer => (
                  <Grid item xs={12} sm={sm} key={aContainer.id} className={classes.item}>
                    {radio(aContainer)}
                  </Grid>
                ))}
              <Grid item xs={12} sm={sm} className={classes.item}>
                <ImageRadio
                  title={t('buttons.EXACT_QUANTITY.title')}
                  onClick={handleChange}
                  value={-1}
                  name="container"
                  checked={container === -1}
                  inputProps={{ 'aria-label': t('buttons.EXACT_QUANTITY.title') }}
                  image="/img/calculator.png"
                />
              </Grid>
            </Grid>
          </RadioGroup>
        </FormControl>
      </Box>
    </Layout>
  );
};

Container.defaultProps = {
  container: null,
};

Container.propTypes = {
  container: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    volume: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired
  }),
  selectContainer: PropTypes.func.isRequired,
  resetQuantity: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    containers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      volume: PropTypes.number.isRequired,
      unit: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.shape({
        percentage: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
      })),
    })),
  }).isRequired,
  submitDelay: PropTypes.number.isRequired,
};

export default Container;
